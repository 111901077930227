<template>
  <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
    <div :class="'Flash Flash--' + level" role="alert" v-show="is_active">
      <div class="Flash__message">
        <Icon class="mr-2" :icon="['fa', iconName]" size="2x" />
        {{ body }}
      </div>
      <a class="Flash__close" @click.prevent="toggle" @keyup="toggle">
        <CloseIcon class="h-6 w-6" />
        <span class="util-sr-only">Close</span>
      </a>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/Icon.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';

export default {
  name: 'Flash',
  components: {
    Icon,
    CloseIcon,
  },
  props: ['message'],
  data() {
    return {
      body: this.message,
      level: 'success',
      is_active: false,
      timeout: 3500,
    };
  },
  created() {
    if (this.message) {
      this.flash();
    }
    window.events.$on('flash', (data) => this.flash(data));
  },
  methods: {
    flash(data) {
      if (this.is_active === true) {
        return;
      }
      if (data.auto_hide) {
        this.auto_hide = data.auto_hide;
      }
      this.body = data.message;
      this.level = data.level;
      this.is_active = true;
      this.timeout = data.timeout || this.timeout;

      if (data.options.type === 'modal') {
        this.renderModal(data);
      } else {
        this.hide();
      }
    },
    renderModal(data) {
      if (data.options.component) {
        this.modalComponent = data.options.component;
      }
    },
    modalSubmitted() {
      this.is_active = false;
      this.$emit('submitted');
    },
    hide() {
      if (this.auto_hide) {
        setTimeout(() => {
          this.is_active = false;
        }, this.timeout);
      }
    },
    toggle() {
      this.is_active = !this.is_active;
    },
  },
  computed: {
    iconName() {
      switch (this.level) {
        case 'danger':
          return 'skull-crossbones';

        case 'info':
          return 'comment';

        case 'warning':
          return 'exclamation-circle';

        case 'success':
        default:
          return 'check-circle';
      }
    },
  },
};
</script>
