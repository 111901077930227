<template>
  <transition name="modal-fade">
    <div ref="modalBg" class="Modal__backdrop" @keyup.enter="confirm" @keyup.esc="close" tabindex="0">
      <div class="Modal__content" role="dialog" aria-labelledby="modalTitle" :aria-describedby="descriptionId">

        <section class="Modal__title">
          <slot name="title"></slot>
        </section>

        <section class="Modal__body" :id="descriptionId">
          <slot name="body">I'm the default body!</slot>
        </section>

        <footer class="Modal__footer">
          <button
            v-if="confirmCallback"
            @click="confirm"
            type="button"
            aria-label="Confirm"
            class="Button Button--full-width"
          >
            {{ confirmText }}
          </button>
          <button
            v-if="closeCallback && showCloseAction"
            @click.prevent="close"
            type="button"
            aria-label="Close modal"
            class="Button Button--outlined"
          >
            {{ closeText }}
          </button>
        </footer>

        <!-- close button should be last -->
        <button class="Modal__close-icon" @click.prevent="close">
          <Icon :icon="['fas', 'xmark']" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'Modal',
  components: {
    Icon,
  },
  props: {
    descriptionId:{
      type: String,
      default: `content-${Math.floor(Math.random()*500)}`
    },
    confirmCallback: {
      type: Function,
      default: null,
    },
    closeCallback: {
      type: Function,
      default: null,
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    closeText: {
      type: String,
      default: 'Close',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    showCloseAction: {
      type: Boolean,
      default: true,
    },
  },
  updated() {
    if (this.isVisible) {
      this.$refs.modalBg.focus();
    }
  },
  methods: {
    close() {
      if (this.closeCallback) {
        this.closeCallback();
      }
    },
    confirm() {
      if (this.confirmCallback) {
        this.confirmCallback();
      }
    },
  },
};
</script>
