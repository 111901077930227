import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Store from './store';
import { ProgressPoints } from './store/modules/respondent';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/get-started',
      name: 'get-started',
      component: () => import(/* webpackChunkName: "get-started" */ './views/GetStarted.vue'),
    },
    {
      path: '/onboard',
      name: 'onboard',
      component: () => import(/* webpackChunkName: "onboard" */ './views/Onboard/OnboardIntro.vue'),
    },
    {
      path: '/onboard/:step',
      name: 'onboard-steps',
      component: () => import(/* webpackChunkName: "onboard-steps" */ './views/Onboard/OnboardSteps.vue'),
      props: ({ params }) => ({ step: Number.parseInt(params.step, 10) }),
      meta: { requiresAuth: true },
    },
    {
      path: '/bus-time-table',
      name: 'bus-time-table',
      component: () => import(/* webpackChunkName: "bus-time-table" */ './views/BusTimeTable.vue'),
    },
    {
      path: '/refine-plan',
      name: 'refine-plan',
      component: () => import(/* webpackChunkName: "my-plan" */ './views/RefinePlan.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/event-detail/:event_id/:event_session_id',
      name: 'event-detail',
      component: () => import(/* webpackChunkName: "event-detail" */ './views/EventDetail.vue'),
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: '/my-plan',
      name: 'my-plan',
      component: () => import(/* webpackChunkName: "my-plan" */ './views/RefinePlan.vue'), // './views/MyPlan.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/about-open-day',
      name: 'about-open-day',
      component: () => import(/* webpackChunkName: "about-open-day" */ './views/AboutOpenDay.vue'),
    },
    {
      path: '/campus-maps',
      name: 'campus-maps',
      component: () => import(/* webpackChunkName: "campus-maps" */ './views/CampusMaps.vue'),
    },
    {
      path: '/postgraduate-info',
      name: 'postgraduate-info',
      component: () => import(/* webpackChunkName: "postgraduate-info" */ './views/PostgraduateInfo.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth =
    to.matched.some((x) => x.meta.requiresAuth) &&
    !(
      to.name === 'onboard-steps' &&
      // The first three steps of the onboarding do not require authentication.
      to.params.step < 4
    );
  const isRespondentAuthenticated = Store.getters['respondent/isAuthenticated'];

  if (requiresAuth && !isRespondentAuthenticated) {
    return next('/');
  }

  return next();
});

/**
 * Return the URL for a respondent's progress point.
 */
export const convertProgressPointToRoute = (progressPoint) => {
  let destinationRoute = '/';

  switch (progressPoint) {
    /* This is the first step after the user has finished creating an account.
    It's not possible to create an account with less progress than this. */
    case ProgressPoints.ONBOARD_STEP_4:
      destinationRoute = '/onboard/4';
      break;
    case ProgressPoints.ONBOARD_STEP_5:
      destinationRoute = '/onboard/5';
      break;
    case ProgressPoints.ONBOARD_STEP_6:
      destinationRoute = '/onboard/6';
      break;
    case ProgressPoints.REFINE_PLAN:
      destinationRoute = '/my-plan';
      break;
    case ProgressPoints.REVIEW_MY_PLAN:
      destinationRoute = '/my-plan';
      break;
    default:
      // Something has gone wrong.
      console.warn('Unable to convert progress point into route.', progressPoint);
      destinationRoute = '/';
      break;
  }

  return destinationRoute;
};

export default router;
