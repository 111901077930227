<template>
  <fragment>
    <div v-if="loading" class="mt-10 mb-5 flex items-center justify-center">
      <LoaderBricks />
    </div>

    <form v-if="!loading" class="Form" @submit.prevent="login">
      <label for="email">
        <input v-model="email" type="email" id="email" placeholder="Email address" class="Form__row" required />
        <span class="sr-only">Email address</span>
      </label>

      <button type="submit" aria-label="Sign in" class="Button Form__row" :disabled="!isFormValid">
        {{ buttonText }}
      </button>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import isEmail from 'validator/es/lib/isEmail';
import { StatusCodes } from 'http-status-codes';
import LoaderBricks from '@/components/LoaderBricks.vue';
import { convertProgressPointToRoute } from '@/router';
import { formatRespondentFromApi } from '@/lib';

export default {
  name: 'LoginForm',
  components: {
    LoaderBricks,
  },
  props: {
    completedUrl: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'Log in',
    },
  },
  mounted() {
    // Set the email address if we know it
    this.email = this.getRespondent ? this.getRespondent.email : '';
  },
  data() {
    return {
      email: '',
      loading: false,
      isConfirmingNewUser: false,
    };
  },
  computed: {
    ...mapState(['respondent']),
    ...mapGetters({
      getRespondent: 'respondent/get',
    }),
    isFormValid() {
      return isEmail(this.email || '');
    },
  },
  methods: {
    ...mapActions({setLocalData:'localStorage/setLocalData'}),
    async login() {
      this.loading = true;

      const respondent = await this.getExistingRespondent();

      if (respondent) {
        // Logging in an existing user.
        await this.$store.dispatch('respondent/login', respondent);

        window.dataLayer.push({
          event: 'FormSubmission',
          form: 'SignIn',
        });
        // this.$store.dispatch("localStorage/clearLocalData");
        // console.log("resp",respondent);
        this.setLocalData(['email', respondent.email]);
        this.setLocalData(['first_name', respondent.first_name]);

        const destinationRoute = convertProgressPointToRoute(respondent.appProgress);
        this.$router.replace(destinationRoute);
      } else if (this.isConfirmingNewUser) {
        // Pre-populate the sign up form.
        this.$store.commit('respondent/set', {
          email: this.email,
        });

        this.$router.push(this.completedUrl);
      } else {
        // Swap to confirmation state for a new user.
        this.$emit('confirm-creation');
        this.isConfirmingNewUser = true;
      }

      this.loading = false;
    },

    async getExistingRespondent() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/auth/check?email=${encodeURIComponent(this.email)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === StatusCodes.OK) {
        const { respondent } = await response.json();
        return formatRespondentFromApi(respondent);
      }

      return undefined;
    },
  },
};
</script>
