export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign -- This is how Vue recommends setting global config.
    Vue.prototype.$config = {
      MMS_URL: process.env.VUE_APP_MATCH_MY_SKILLS_URL,
      QUT_STUDY_URL: process.env.VUE_APP_QUT_STUDY_URL,
      CAMPUS: {
        gardensPoint: {
          lat: -27.477255,
          lng: 153.028421,
        },
        kelvinGrove: {
          lat: -27.4527184034932,
          lng: 153.01526539860546,
        },
      },
    };
  },
};
