<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.008 15">
    <path
      class="fill-current"
      d="M5.828 9.84a.749.749 0 0 0 .53-.22l7.06-7.06v3.65a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-.75-.75h-5.46a.75.75 0 0 0 0 1.5h3.65l-7.11 7.06a.75.75 0 0 0 .53 1.28z"
    />
    <path
      class="fill-current"
      d="M14.423 8.119a.577.577 0 0 0-.577.577v4.767a.376.376 0 0 1-.377.377H1.538a.376.376 0 0 1-.377-.377V1.53a.376.376 0 0 1 .377-.38h4.513a.577.577 0 1 0 0-1.153H1.538A1.537 1.537 0 0 0 0 1.538v11.924A1.537 1.537 0 0 0 1.538 15H13.47a1.537 1.537 0 0 0 1.538-1.538V8.696a.577.577 0 0 0-.577-.577z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExternalLinkIcon',
};
</script>
