<template>
  <div id="app" class="App">
    <ul class="skip-links">
      <li>
        <a href="#main" ref="skipLink" class="skip-link">Skip to main content</a>
      </li>
    </ul>
    <Flash />
    <Navigation v-if="navigationEnabled" />
    <router-view id="main"/>
    <AlreadyLoggedInModal/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Flash from '@/components/Flash.vue';
import Navigation from '@/components/Navigation.vue';
import AlreadyLoggedInModal from '@/components/AlreadyLoggedInModal.vue';

export default {
  components: {
    Flash,
    Navigation,
    AlreadyLoggedInModal,
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.$store.dispatch('events/get').then(() => {
        if (this.$store.state.respondent.activeEventStartTimes.length <= 0) {
          this.$store.dispatch('respondent/setEventStartTimeFilter', { start: 0, end: 24 });
        }
      });
    },
  },
  computed: {
    ...mapState(['respondent']),
    navigationEnabled() {
      return this.$route.name !== 'home';
    },
  },
};
</script>
