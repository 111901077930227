
// prettier-ignore
export default {
  "countries": [
    "Australia (+61)",
    "New Zealand (+64)",
    "Afghanistan (93)",
    "Åland Islands (358)",
    "Albania (355)",
    "Algeria (213)",
    "American Samoa (1-684)",
    "Andorra (376)",
    "Angola (244)",
    "Anguilla (1-264)",
    "Antarctica (672)",
    "Antigua and Barbuda (1-268)",
    "Argentina (54)",
    "Armenia (374)",
    "Aruba (297)",
    "Austria (43)",
    "Azerbaijan (994)",
    "Bahamas (the) (1-242)",
    "Bahrain (973)",
    "Bangladesh (880)",
    "Barbados (1-246)",
    "Belarus (375)",
    "Belgium (32)",
    "Belize (501)",
    "Benin (229)",
    "Bermuda (1-441)",
    "Bhutan (975)",
    "Bolivia (Plurinational State of) (591)",
    "Bonaire, Sint Eustatius and Saba (599)",
    "Bosnia and Herzegovina (387)",
    "Botswana (267)",
    "Bouvet Island (55)",
    "Brazil (55)",
    "British Indian Ocean Territory (the) (246)",
    "Brunei Darussalam (673)",
    "Bulgaria (359)",
    "Burkina Faso (226)",
    "Burundi (257)",
    "Cabo Verde (238)",
    "Cambodia (855)",
    "Cameroon (237)",
    "Canada (1)",
    "Cayman Islands (the) (1-345)",
    "Central African Republic (the) (236)",
    "Chad (235)",
    "Chile (56)",
    "China (86)",
    "Christmas Island (61)",
    "Cocos (Keeling) Islands (the) (61)",
    "Colombia (57)",
    "Comoros (the) (269)",
    "Congo (the Democratic Republic of the) (243)",
    "Congo (the) (242)",
    "Cook Islands (the) (682)",
    "Costa Rica (506)",
    "Côte d'Ivoire (225)",
    "Croatia (385)",
    "Cuba (53)",
    "Curaçao (599)",
    "Cyprus (357)",
    "Czechia (420)",
    "Denmark (45)",
    "Djibouti (253)",
    "Dominica (1-767)",
    "Dominican Republic (the) (1-809, 1-829, 1-849)",
    "Ecuador (593)",
    "Egypt (20)",
    "El Salvador (503)",
    "Equatorial Guinea (240)",
    "Eritrea (291)",
    "Estonia (372)",
    "Eswatini (268)",
    "Ethiopia (251)",
    "Falkland Islands (the) [Malvinas] (500)",
    "Faroe Islands (the) (298)",
    "Fiji (679)",
    "Finland (358)",
    "France (33)",
    "French Guiana (594)",
    "French Polynesia (689)",
    "French Southern Territories (the) (262)",
    "Gabon (241)",
    "Gambia (the) (220)",
    "Georgia (995)",
    "Germany (49)",
    "Ghana (233)",
    "Gibraltar (350)",
    "Greece (30)",
    "Greenland (299)",
    "Grenada (1-473)",
    "Guadeloupe (590)",
    "Guam (1-671)",
    "Guatemala (502)",
    "Guernsey (44-1481)",
    "Guinea (224)",
    "Guinea-Bissau (245)",
    "Guyana (592)",
    "Haiti (509)",
    "Heard Island and McDonald Islands (672)",
    "Holy See (the) (379)",
    "Honduras (504)",
    "Hong Kong (852)",
    "Hungary (36)",
    "Iceland (354)",
    "India (91)",
    "Indonesia (62)",
    "Iran (Islamic Republic of) (98)",
    "Iraq (964)",
    "Ireland (353)",
    "Isle of Man (44-1624)",
    "Israel (972)",
    "Italy (39)",
    "Jamaica (1-876)",
    "Japan (81)",
    "Jersey (44-1534)",
    "Jordan (962)",
    "Kazakhstan (7)",
    "Kenya (254)",
    "Kiribati (686)",
    "Korea (the Democratic People's Republic of) (850)",
    "Korea (the Republic of) (82)",
    "Kuwait (965)",
    "Kyrgyzstan (996)",
    "Lao People's Democratic Republic (the) (856)",
    "Latvia (371)",
    "Lebanon (961)",
    "Lesotho (266)",
    "Liberia (231)",
    "Libya (218)",
    "Liechtenstein (423)",
    "Lithuania (370)",
    "Luxembourg (352)",
    "Macao (853)",
    "Madagascar (261)",
    "Malawi (265)",
    "Malaysia (60)",
    "Maldives (960)",
    "Mali (223)",
    "Malta (356)",
    "Marshall Islands (the) (692)",
    "Martinique (596)",
    "Mauritania (222)",
    "Mauritius (230)",
    "Mayotte (262)",
    "Mexico (52)",
    "Micronesia (Federated States of) (691)",
    "Moldova (the Republic of) (373)",
    "Monaco (377)",
    "Mongolia (976)",
    "Montenegro (382)",
    "Montserrat (1-664)",
    "Morocco (212)",
    "Mozambique (258)",
    "Myanmar (95)",
    "Namibia (264)",
    "Nauru (674)",
    "Nepal (977)",
    "Netherlands (the) (31)",
    "New Caledonia (687)",
    "Nicaragua (505)",
    "Niger (the) (227)",
    "Nigeria (234)",
    "Niue (683)",
    "Norfolk Island (672)",
    "North Macedonia (389)",
    "Northern Mariana Islands (the) (1-670)",
    "Norway (47)",
    "Oman (968)",
    "Pakistan (92)",
    "Palau (680)",
    "Palestine, State of (970)",
    "Panama (507)",
    "Papua New Guinea (675)",
    "Paraguay (595)",
    "Peru (51)",
    "Philippines (the) (63)",
    "Pitcairn (64)",
    "Poland (48)",
    "Portugal (351)",
    "Puerto Rico (1-787, 1-939)",
    "Qatar (974)",
    "Réunion (262)",
    "Romania (40)",
    "Russian Federation (the) (7)",
    "Rwanda (250)",
    "Saint Barthélemy (590)",
    "Saint Helena, Ascension and Tristan da Cunha (290)",
    "Saint Kitts and Nevis (1-869)",
    "Saint Lucia (1-758)",
    "Saint Martin (French part) (590)",
    "Saint Pierre and Miquelon (508)",
    "Saint Vincent and the Grenadines (1-784)",
    "Samoa (685)",
    "San Marino (378)",
    "Sao Tome and Principe (239)",
    "Saudi Arabia (966)",
    "Senegal (221)",
    "Serbia (381)",
    "Seychelles (248)",
    "Sierra Leone (232)",
    "Singapore (65)",
    "Sint Maarten (Dutch part) (1-721)",
    "Slovakia (421)",
    "Slovenia (386)",
    "Solomon Islands (677)",
    "Somalia (252)",
    "South Africa (27)",
    "South Georgia and the South Sandwich Islands (500)",
    "South Sudan (211)",
    "Spain (34)",
    "Sri Lanka (94)",
    "Sudan (the) (249)",
    "Suriname (597)",
    "Svalbard and Jan Mayen (47)",
    "Sweden (46)",
    "Switzerland (41)",
    "Syrian Arab Republic (the) (963)",
    "Taiwan (Province of China) (886)",
    "Tajikistan (992)",
    "Tanzania, the United Republic of (255)",
    "Thailand (66)",
    "Timor-Leste (670)",
    "Togo (228)",
    "Tokelau (690)",
    "Tonga (676)",
    "Trinidad and Tobago (1-868)",
    "Tunisia (216)",
    "Turkey (90)",
    "Turkmenistan (993)",
    "Turks and Caicos Islands (the) (1-649)",
    "Tuvalu (688)",
    "Uganda (256)",
    "Ukraine (380)",
    "United Arab Emirates (the) (971)",
    "United Kingdom of Great Britain and Northern Ireland (the) (44)",
    "United States Minor Outlying Islands (the)United States of America (the) (1)",
    "Uruguay (598)",
    "Uzbekistan (998)",
    "Vanuatu (678)",
    "Venezuela (Bolivarian Republic of) (58)",
    "Viet Nam (84)",
    "Virgin Islands (British) (1-284)",
    "Virgin Islands (U.S.) (1-340)",
    "Wallis and Futuna (681)",
    "Western Sahara* (212)",
    "Yemen (967)",
    "Zambia (260)",
    "Zimbabwe (263)"
  ],
  "localeMap": {
    "Virgin Islands (U.S.) (1)" : "Virgin Islands (U.S.) (1-340)",
    "Turks and Caicos Islands (the) (1)" : "Turks and Caicos Islands (the) (1-649)",
    "Trinidad and Tobago (1)" : "Trinidad and Tobago (1-868)",
    "Taiwan (886)" : "Taiwan (Province of China) (886)",
    "Sint Maarten (Dutch part) (1)" : "Sint Maarten (Dutch part) (1-721)",
    "Saint Vincent and the Grenadines (1)" : "Saint Vincent and the Grenadines (1-784)",
    "Saint Lucia (1)" : "Saint Lucia (1-758)",
    "Saint Kitts and Nevis (1)" : "Saint Kitts and Nevis (1-869)",
    "Puerto Rico (1)" : "Puerto Rico (1-787, 1-939)",
    "Northern Mariana Islands (the) (1)" : "Northern Mariana Islands (the) (1-670)",
    "Montserrat (1)" : "Montserrat (1-664)",
    "Jersey (44)" : "Jersey (44-1534)",
    "Jamaica (1)" : "Jamaica (1-876)",
    "Isle of Man (44)" : "Isle of Man (44-1624)",
    "Guernsey (44)" : "Guernsey (44-1481)",
    "Guam (1)" : "Guam (1-671)",
    "Grenada (1)" : "Grenada (1-473)",
    "Dominican Republic (the) (1)" : "Dominican Republic (the) (1-809, 1-829, 1-849)",
    "Dominica (1)" : "Dominica (1-767)",
    "Cayman Islands (the) (1)" : "Cayman Islands (the) (1-345)",
    "Bermuda (1)" : "Bermuda (1-441)",
    "Barbados (1)" : "Barbados (1-246)",
    "Bahamas (the) (1)" : "Bahamas (the) (1-242)",
    "Antigua and Barbuda (1)" : "Antigua and Barbuda (1-268)",
    "Anguilla (1)" : "Anguilla (1-264)",
    "American Samoa (1)" : "American Samoa (1-684)",
    "United States of America (the) (1)" : "United States Minor Outlying Islands (the)United States of America (the) (1)",
    "Cook Islands (682)": "Cook Islands (the) (682)",
  }
};
