<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      class="fill-current"
      d="M19.626 8.952l-4.835 4.835-4.835-4.835-.936.936 4.835 4.836-4.835 4.835.936.936 4.835-4.835 4.835 4.835.936-.936-4.835-4.835 4.835-4.836z"
    />
    <g>
      <path fill="none" d="M15 0A15 15 0 1 1 0 15 15 15 0 0 1 15 0z" />
      <path
        class="fill-current"
        d="M15 1c-3.74 0-7.255 1.456-9.9 4.1A13.908 13.908 0 0 0 1 15c0 3.74 1.456 7.255 4.1 9.9A13.908 13.908 0 0 0 15 29c3.74 0 7.255-1.456 9.9-4.1A13.908 13.908 0 0 0 29 15c0-3.74-1.456-7.255-4.1-9.9A13.908 13.908 0 0 0 15 1m0-1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
};
</script>
