<template>
  <div
    class="Container--content-centered-vertical Container--full-width Container--full-height Bg_gradient-image text-center px-8 text-white"
  >
    <div class="Centered-content-container">
      <div class="Bg_gradient-image-content">
        <Logo class="Icon Icon--logo mb-8" />

        <h1 class="uppercase text-10xl leading-tight text-white">Open Day Planner</h1>
        <h3 class="text-white font-charlie mb-8">
          Sunday 28 July 2024<br />
          9am-3pm
        </h3>
        <p class="text-lg font-delta mb-16 font-italics">Discover the real QUT.</p>
        <p class="text-lg font-delta font-italics">Create a personalised plan for Open Day and enter the draw for a MacBook Pro*.</p>

        <!-- <div class="p-4 bg-white my-8">
          <p class="text-lg text-black">Open Day will be held on Sunday, rain or shine. Some activities may change so <a href="https://www.qut.edu.au/study/events/qut-open-day-2023">check the website for details</a></p>
        </div> -->

        <button class="Button mb-12 focus-outline-white" @click="getStarted">Enter</button>
        <a href="#" v-if="showLoginLink" @click.prevent="login" class="block"
          >Already made a plan? Log in.</a
        >
        <small class="mt-6" style="display: block;">* Terms and conditions apply</small>
        <small class="font-delta mt-16 px-2" style="display: block;">TEQSA Provider ID: <a href="https://www.teqsa.gov.au/national-register/provider/queensland-university-technology">PRV12079</a> (Australian University)<br>CRICOS No. <a href="https://cricos.education.gov.au/Institution/InstitutionDetails.aspx?ProviderCode=00213J">00213J</a></small>
      </div>

      <LoginModal :show="isLoginModalVisible" completedUrl="/get-started" @close="closeLoginModal" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/svg/Logo.vue';
import LoginModal from '@/components/LoginModal.vue';

export default {
  components: {
    Logo,
    LoginModal,
  },
  data() {
    return {
      isLoginModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      isRespondentAuthenticated: 'respondent/isAuthenticated',
      isRespondentPreAuthenticated: 'respondent/isPreAuthenticated',
    }),
    showLoginLink() {
      return !this.isRespondentAuthenticated && !this.isRespondentPreAuthenticated;
    },
  },
  methods: {
    getStarted() {
      window.dataLayer.push({
        event: 'Click',
        action: 'Get started',
        label: this.$route.name,
      });

      this.$router.push('/get-started');
    },

    login() {
      this.isLoginModalVisible = !this.isLoginModalVisible;
      const action = this.isLoginModalVisible ? 'Open' : 'Close';

      window.dataLayer.push({
        event: 'SignIn',
        action: `Form ${action}`,
        label: this.$route.name,
      });
    },

    closeLoginModal() {
      this.isLoginModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.font-italics {
  font-style: italic;
}

</style>
