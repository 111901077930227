<template>
  <modal v-show="show" :closeCallback="close" :isVisible="show" description-id="login-modal">
    <fragment slot="title">
      <h1 ref="mountedFocus" tabindex="0">
        <span v-if="isConfirmingNewUser">Unrecognised email</span>
        <span v-else>Log in</span>
      </h1>
    </fragment>

    <fragment slot="body">
      <p class="mb-4 text-black">
        <span v-if="isConfirmingNewUser"
          >We don't recognise this email. Would you like to create a new plan with this one?</span
        >
        <span v-else>Enter the email you used to create your plan.</span>
      </p>
      <LoginForm
        :buttonText="isConfirmingNewUser ? 'Yes, create a plan' : 'Log in'"
        :completedUrl="completedUrl"
        @confirm-creation="isConfirmingNewUser = true"
        class="mb-4"
      />
    </fragment>
  </modal>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'LoginModal',
  components: {
    LoginForm,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    completedUrl: {
      type: String,
      required: true,
    },
  },
  mounted(){
    this.$refs.mountedFocus.focus();
  },
  data() {
    return {
      isConfirmingNewUser: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },

    viewPlan() {
      this.$router.push('/my-plan');
    },
  },
};
</script>
