import { slugify } from '@/lib';
import { groupBy, uniqBy, sortBy, intersection, forEach } from 'lodash';

const defaultState = {
  data: [],
  faculties: [],
  subcategories: [],
  eventStartHours: [],
};

const eventTypes = {
  SEMINAR: 'Seminar',
  TOUR: 'Tour',
  ACTIVITY: 'Activity',
  FACILITY: 'Facility',
};

/**
 * Statically order the faculties from the API.
 */
const FACULTY_ORDER = [
  'Architecture',
  'Built environment',
  'Business',
  'Communication',
  'Creative arts',
  'Design',
  'Engineering',
  'Health',
  'Justice',
  'Information technology and games',
  'Languages',
  'Law',
  'Mathematics and data science',
  'Pathways and diplomas',
  'Research courses and projects',
  'Science',
  'Teaching and education',
];

// 'Architecture' => 'Architecture and Built Environment',
//         'Built environment' => 'Architecture and Built Environment',
//         'Business' => 'Business',
//         'Communication' => 'Communication',
//         'Creative arts' => 'Creative industries',
//         'Design' => 'Design',
//         'Teaching and education' => 'Education',
//         'Engineering' => 'Engineering',
//         'Health' => 'Health',
//         'Information technology and games' => 'Information technology',
//         'Justice' => 'Justice',
//         'Law' => 'Law',
//         'Mathematics and data science' => 'Mathematics',
//         'Science' => 'Science',
//         'General' => 'I am not sure',
//         'Languages' => '',
//         'Pathways and diplomas' => '',
//         'Research courses and projects' => '',

/**
 * Return only event sessions having a start time that is in the
 * activeEventStartTimes array.
 * Given pre-selection of All day on find-events this will always run,
 * hence defensive check of eventSessionsToShow.length
 */
function filterByStartTime(state, data, allDayOnly) {
  return data.filter((session) => {
    const time = parseInt(session.starting_hour, 10);
    if(!allDayOnly)
      return state.activeEventStartTimes.includes(time) && session.is_all_day !== 1 && session.starting_hour.toLowerCase() !== 'all day';

    return session.is_all_day === 1 || session.starting_hour.toLowerCase() === 'all day';
  });
}

const eventModule = {
  namespaced: true,
  state: defaultState,
  actions: {
    get(context) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_API_URL}/events`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            context.commit('set', data);
            console.log(data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    set(state, data) {
      state.data = data.events;
      state.faculties = data.faculties;
      state.subcategories = data.subcategories;
    },

    setProperty(state, { key, value }) {
      state[key] = value;
    },
  },
  getters: {
    eventTypes(state) {
      return uniqBy(
        state.data.map((item) => item.event_type),
        'id',
      );
    },

    sessions(state, getters) {
      // A list of campus tours and event seminar id's that
      // we are going to be removing from the sessions.
      const removedEventIds = [
        ...getters.generalSeminars.map((event) => event.id),
        ...getters.campusTours.map((event) => event.id),
      ];
      return state.data
        .filter((event) => !removedEventIds.includes(event.id))
        .filter((event) => event.faculty)
        .flatMap((item) => item.sessions);
    },

    generalSeminars(state) {
      const generalSeminars = state.data.filter(
        (event) =>
          event.faculty &&
          slugify(event.faculty.title) === 'general' &&
          slugify(event.event_type.title) === slugify(eventTypes.SEMINAR),
      );
      const sortedGeneralSeminars = sortBy(generalSeminars, 'title');
      return sortedGeneralSeminars;
    },

    /**
     * Get event sessions that are general (NOT faculty) tours.
     */
    campusTours(state) {
      return state.data.filter((event) => {
        const isCampusTour =
          // Find all tour events ...
          slugify(event.event_type.title) === 'guided-tour' &&
          // that are "general" and not a study area (faculty) specific tour.
          event.faculty &&
          slugify(event.faculty.title) === 'general';

        return isCampusTour;
      });
    },

    /**
     * Get campus tours grouped by their title. This represents a "type" of campus
     * tour i.e. "Gardens Point campus tour with a QUT student".
     */
    campusTourTypes(state, getters) {
      return uniqBy(getters.campusTours.flatMap((item) => item.title));
    },

    /**
     * Get event sessions that are study area tours.
     */
    facultyTours(state) {
      return state.data.filter((event) => {
        const isFacultyTour =
          // Find all tour events ...
          (slugify(event.event_type.title) === 'guided-tour' || slugify(event.event_type.title) === 'open-house') &&
          // that are "general" and not a study area (faculty) specific tour.
          event.faculty &&
          slugify(event.faculty.title) !== 'general';

        return isFacultyTour;
      });
    },

    /**
     * Get faculty (study area) tours grouped by their title, based on the the faculties
     * the user has selected.
     */
    facultyToursTypes(state, getters, rootState) {
      // Only include tours from faculties the user has already selected.
      const facultyTours = getters.facultyTours.filter((tour) => {
        const isTourForActiveFaculty = rootState.respondent.activeFacultyFilters.includes(tour.faculty.id);
        return isTourForActiveFaculty;
      });

      return uniqBy(facultyTours.flatMap((item) => item.title));
    },

    eventStartHours(state) {
      return uniqBy(state.data.flatMap((item) => item.sessions).map((item) => parseInt(item.starting_hour, 10)))
        .filter(Boolean)
        .sort((a, b) => a - b);
    },

    /**
     * Return appropriate events based on selected filters
     *
     * @param   {Object}  state  The state object
     *
     * @return  {Array}          An array of event objects
     */
    filteredEventSessions(state, getters, rootState) {
      let sessions = [];
      /*
       * Mystical Dark magics
       * https://stackoverflow.com/questions/51171822/adding-conditions-to-filter-dynamically-in-javascript
       */

      // set the conditionals we will be using.
      const conditions = [];
      /**
       * Return events whose faulty id is in activeFacultyFilters
       */

      // An array of sessions that we will be pushing at the end (e.g. tours)
      const additionalSessions = [];

      // An array of faculty IDs that should trigger events categories with "All" to show
      // const allFaculties = [1,2,3,4,6,8,10,11,12,13,14,15,16,17];

      console.log("activeSubcategoryFilter",rootState.respondent.activeSubcategoryFilter,
      "activeFacultyFilters",rootState.respondent.activeFacultyFilters,
      "activeDisciplineFilters",rootState.respondent.activeDisciplineFilters,
      "activeEventStartTimes",rootState.respondent.activeEventStartTimes,
      "activeCampusTourFilters",rootState.respondent.activeCampusTourFilters,
      "activeFacultyTourFilters",rootState.respondent.activeFacultyTourFilters,
      )

      if (
        rootState.respondent.activeDisciplineFilters.length > 0 &&
        rootState.respondent.activeFacultyFilters.length > 0
      ) {
        conditions.push((session) => rootState.respondent.activeFacultyFilters.includes(session.event.faculty_id));
        conditions.push((session) =>
          session.disciplines.some((discipline) =>
            rootState.respondent.activeDisciplineFilters.includes(discipline.title),
          ),
        );

        const facultyToursAndSeminars = getters.generalSeminars
          .filter((event) => rootState.respondent.activeFacultyFilters.includes(event.faculty_id))
          .flatMap((event) => event.sessions)
          .filter((session) =>
            session.disciplines.some((discipline) =>
              rootState.respondent.activeDisciplineFilters.includes(discipline.title),
            ),
          );

        additionalSessions.push(...facultyToursAndSeminars);
      }

      // Add campus tours if filter is active
      if (rootState.respondent.activeCampusTourFilters.length > 0) {
        const tours = getters.campusTours
          .flatMap((event) => event.sessions)
          .filter((session) => rootState.respondent.activeCampusTourFilters.includes(session.title));

        additionalSessions.push(...tours);
      }

      // Add faculty tours if filter is active.
      if (rootState.respondent.activeFacultyTourFilters.length > 0) {
        const tours = getters.facultyTours
          .flatMap((event) => event.sessions)
          .filter((session) => rootState.respondent.activeFacultyTourFilters.includes(session.title));

        additionalSessions.push(...tours);
      }

      // Add specific events if any exists
      if (rootState.respondent.activeSubcategoryFilter.length > 0) {
        const subcategoryEvents = state.data
          .filter((event) => {
            const intersecting = intersection(
              event.subcategories.map((subcategory) => subcategory.title),
              rootState.respondent.activeSubcategoryFilter,
            );
            return intersecting.length > 0;
          })
          .filter((event) => slugify(event.faculty.title) === 'general')
          .flatMap((event) => event.sessions);

        additionalSessions.push(...subcategoryEvents);
      }

      // Add events categories with 'All' if faculties in 'allFaculties' are selected
      // if (rootState.respondent.activeFacultyFilters.length > 0) {
      //   const categoryAllEvents = state.data
      //     .filter((event) => rootState.respondent.activeFacultyFilters.some(r=> allFaculties.indexOf(r) >= 0) && event.faculty_id === 5)
      //     .flatMap((event) => event.sessions);

      //   additionalSessions.push(...categoryAllEvents);
      // }

      // Add event with 'All' selected if they're not postgraduate
      console.log("activeDisciplineFilters",rootState.respondent.activeDisciplineFilters);
      const disci = rootState.respondent.activeDisciplineFilters;
      let isPG = false;
      // !rootState.respondent.isPostgraduate ||
      if( (  disci.length === 1 &&
            disci.indexOf("MBA and professional development") === 0
          ) ||
          (disci.length === 1  &&
            disci.indexOf("Research courses and projects") === 0
          ) ||
          (disci.length === 2 &&
            disci.indexOf("MBA and professional development") >= 0 &&
            disci.indexOf("Research courses and projects") >= 0
          )){
            isPG = true;
      }
      // THIS CONDITION WAS LAST MINUTE LEAVE ME ALONE - GG
      if(!isPG){
        console.log("adding all");
        const categoryAllEvents = state.data
          .filter((event) => event.faculty.title === 'All')
          .flatMap((event) => event.sessions);
          console.log(categoryAllEvents);
          additionalSessions.push(...categoryAllEvents);
      }

      if (
        rootState.respondent.activeDisciplineFilters.length > 0 &&
        rootState.respondent.activeFacultyFilters.length > 0
      ) {
        sessions = getters.sessions.filter((object) => conditions.every((condition) => condition(object)));
      }

      const eventsInPlan = [];
      if(rootState.respondent.events.length > 0){
        forEach(rootState.respondent.events, (e)=>{
          const evnt = {...e};
          delete evnt.sessions;
          evnt.inPlanNotFilters = true;
          eventsInPlan.push(evnt);
        })
        console.log("eventsInPlan",eventsInPlan);
      }


      // Combine results but make sure they are unique and not duplicated.
      const finalResult = uniqBy([...sessions, ...additionalSessions, ...eventsInPlan], 'id');

      let finalResultTimes;
      if (rootState.respondent.activeEventStartTimes.length > 0) {
        finalResultTimes = filterByStartTime(rootState.respondent, finalResult, false);
      }

      finalResultTimes = sortBy(finalResultTimes, 'start_time');
      console.log("finalResult",groupBy(finalResultTimes, 'starting_hour'));

      const finalResultAllDay = uniqBy(filterByStartTime(rootState.respondent, finalResult, true), (item) => item.title+item.campus);

      forEach(finalResultAllDay, (allDayEvent)=>{
        finalResultAllDay.find((e) => e.id === allDayEvent.id).sessions = sortBy(state.data.find((e) => e.id === allDayEvent.event_id).sessions, 'start_time');
      })

      // console.log("EVENT STATES",state, getters, rootState);
      // const eventSessionsSorted = sortBy(finalResult, 'start_time').filter(
      //   (session) => session.starting_hour !== 'All Day',
      // );

      // const eventSessionTimeGroups = groupBy(eventSessionsSorted, 'starting_hour');

      return {timed: groupBy(finalResultTimes, 'starting_hour'), allDay: finalResultAllDay };
    },

    /**
     * Sort list of faculties by static ordering configuration.
     *
     * @param {*} faculties
     * @returns
     */
    orderedFaculties(state) {
      return state.faculties.sort((a, b) => FACULTY_ORDER.indexOf(a.title) - FACULTY_ORDER.indexOf(b.title));
    },
  },
};

export default eventModule;
