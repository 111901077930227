<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path fill="#fff" d="M0 48h48V0H0z" />
      <path fill="#06487D" d="M45.977 9.104h-3.586v13.333h-4.598V9.104H34.76L30.62 4.782h15.357z" />
      <path
        d="M34.483 17.655c0 .92-.92 5.057-6.621 5.057-5.701 0-6.805-4.138-6.805-5.057V4.782h4.322v12.322c0 .275 0 1.47 2.391 1.47 2.299 0 2.299-1.103 2.299-1.379V5.701l4.414 4.598v7.356z"
        fill="#06487D"
      />
      <path
        d="M17.471 22.437l-8.275-8.552h4.873l1.287 1.38c.184-.552.368-1.104.276-1.656 0-2.574-2.115-4.69-4.69-4.69-2.574 0-4.69 2.116-4.69 4.69 0 2.575 2.116 4.69 4.69 4.69.276 0 .552 0 .828-.092l3.403 3.586c-.92.644-2.76 1.012-4.23 1.012-5.058 0-8.92-4.322-8.92-9.104a9.185 9.185 0 0 1 9.195-9.011 9.185 9.185 0 0 1 9.012 9.195c-.092 1.655-.552 3.31-1.471 4.782l3.77 3.77H17.47z"
        fill="#06487D"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
};
</script>
