<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14.937">
    <path
      class="fill-current"
      d="M7.994 7.361a.8.8 0 0 0-.216-.44L1.406.283A.8.8 0 0 0 .842.001a.8.8 0 0 0-.589 1.377l5.849 6.09-5.849 6.09a.8.8 0 1 0 1.153 1.1L7.778 8.02a.8.8 0 0 0 .216-.655z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronIcon',
};
</script>
