import { forEach, groupBy, sortBy, filter } from 'lodash';
import { StatusCodes } from 'http-status-codes';
import { allEqual } from '@/lib';
import { SessionFullError, PlanFullError, UnableToEmailPlanError } from '@/lib/error';

export const ProgressPoints = {
  /**
   * Default state.
   */
  START: 1,

  /**
   * Onboarding - Select study areas.
   */
  ONBOARD_STEP_1: 2,

  /**
   * Onboarding - Refine study areas.
   */
  ONBOARD_STEP_2: 3,

  /**
   * Onboarding - Collect user details and create respondent.
   */
  ONBOARD_STEP_3: 4,

  /**
   * Onboarding - Select attendance times.
   */
  ONBOARD_STEP_4: 5,

  /**
   * Onboarding - Select general, and study area, tours.
   */
  ONBOARD_STEP_5: 6,

  /**
   * Onboarding - Select other events.
   */
  ONBOARD_STEP_6: 7,

  /**
   * Onboarding - Review and select available events based on earlier data.
   */
  REFINE_PLAN: 8,

  /**
   * Onboarding - Review a completed plan.
   */
  REVIEW_MY_PLAN: 9,
};

// Some state properties have snake cased names to match the API response contract.
const defaultState = {
  // Respondent data.
  id: null,
  hashedID: null,
  email: null,
  mobile: null,
  keep_in_touch: false,
  first_name: null,
  last_name: null,
  profile_edu_level: '',
  isPostgraduate: false,

  // Local only data.
  events: [], // Local (browser) chosen events
  sessions: [], // Stored events (on the DB)
  maxEventsPerTimeSlot: 3,
  maxAllDayEvents: 10,
  planTab: 'timed',
  planCampus: 'All',
  openCardIds: [],

  // Respondent settings (sync with API).
  activeSubcategoryFilter: [],
  activeFacultyFilters: [],
  activeDisciplineFilters: [],
  activeEventStartTimes: [],
  activeCampusTourFilters: [],
  activeFacultyTourFilters: [],

  /**
   * Has the user added at least one event to their plan?
   */
  addedFirstEvent: false,

  /**
   * Has the user been shown the campus travel modal?
   */
  shownCampusTravelModel: false,

  /**
   * Has the user been shown the All day events modal?
   */
  shownAllDayModal: false,

  /**
   * Progress through the app that is considered a "milestone" i.e. the user has
   * progressed to a point where further functionality is unlocked.
   */
  appProgress: ProgressPoints.START,

  /**
   * Time event filters are stored in the events module as a state.activeEventStartTimes
   * which is an array of available event start times.
   *
   * @default 24 hours (all day)
   */
  attendingTime: {
    start: 0,
    end: 24,
  },
};

/**
 * Helper method
 * Add X empty object(s) to the given array key
 * Used to fill empty time slots in the eventSessions
 */
const addEmptyEvent = (howMany, toWhat, toWhere) => {
  for (let index = 0; index < howMany; index += 1) {
    toWhat[toWhere].push({});
  }
};

const respondentModule = {
  namespaced: true,
  state: { ...defaultState },
  actions: {
    emailMyPlan(context) {
      console.log("STUFF",context.state.activeFacultyFilters,context.state.activeDisciplineFilters);
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_API_URL}/auth`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: context.state.email.replaceAll("+","%2B"),
            first_name: context.state.first_name,
            last_name: context.state.last_name,
            profile_edu_level: context.state.profile_edu_level,
            keep_in_touch: context.state.keep_in_touch,
            eventTriggerId: process.env.VUE_APP_EMAIL_API_TRIGGER_ID,
            SPAD__c: 'open_day_2024_email_plan',
            facultyIds: context.state.activeFacultyFilters,
            disciplines: context.state.activeDisciplineFilters,
          }),
        })
          .then((response) => {
            if (response.status !== StatusCodes.OK) {
              throw new UnableToEmailPlanError();
            }

            return response.json();
          })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /**
     * Create a respondent.
     */
    async auth(context, details) {
      console.log("filters",context.state.activeDisciplineFilters,context.state.activeFacultyFilters)
      const response = await fetch(`${process.env.VUE_APP_API_URL}/auth`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...details,
          facultyIds: context.state.activeFacultyFilters,
          disciplines: context.state.activeDisciplineFilters,
          SPAD__c: 'open_day_2024_planner',
        }),
      });

      const { respondent } = await response.json();
      // Persist newly created respondent ID and any data we store in our DB.
      context.commit('set', respondent);
    },

    /**
     * Login an existing respondent by persisting it to the local state. Does NOT
     * contact the API, instead operating on respondent data already be loaded by
     * other actions.
     *
     * @param {*} context
     * @param {*} respondent Respondent from API endpoint such as check/auth.
     * @returns
     */
    login(context, respondent) {
      // Synchronise local respondent state with any persisted state from API.
      context.commit('set', respondent);
      return respondent;
    },

    logout(context) {
      context.commit('reset', defaultState);
    },

    toggleEvent(context, event) {
      if (context.state.events.find((item) => item.id === event.id)) {
        context.commit('removeEvent', event);
        if (context.state.id) {
          context.dispatch('deleteSession', event);
        }
      } else {
        console.log("activeEventStartTimes",context.state.activeEventStartTimes);

        const timeslot = context.getters.eventsPerTimeSlot[event.starting_hour];

        if(event.is_all_day === 0) {
          const isPlanFull = context.state.activeEventStartTimes.every((planTimeSlot) => {
            /* There is only one event available in this timeslot, so it would not
            be possible to fill it with 3 events. */
            if (planTimeSlot === 8) {
              return true;
            }

            const events = context.getters.sortedEvents.timed[planTimeSlot];
            console.log("events",context.getters.sortedEvents);
            if (!events) {
              return false;
            }

            return events.every((item) => Boolean(item.id));
          });
          if (isPlanFull) {
            throw new PlanFullError();
          }

          const isTimeslotFull = timeslot && timeslot.length === 3;
          if (isTimeslotFull) {
            throw new SessionFullError();
          }
        }
        // console.log("eventsPerTimeSlot",context.getters.eventsPerTimeSlot);

        if (!context.state.addedFirstEvent) {
          context.commit('set', { addedFirstEvent: true });
        }

        const session = { ...event, active: !timeslot };
        context.commit('addEvent', session);
        if (context.state.id) {
          context.dispatch('saveSession', session);
          if (session.active) {
            context.dispatch('sendActiveSession', session);
          }
        }
      }
    },

    /**
     * Add a singular event locally and to the DB.
     */
    addEvent(context, event) {
      const timeslot = context.getters.eventsPerTimeSlot[event.starting_hour];

      context.commit('addEvent', {
        ...event,
        active: !timeslot,
      });

      if (context.state.id) {
        context.dispatch('saveSession', {
          ...event,
          active: !timeslot,
        });
      }

      window.dataLayer.push({
        event: 'AddEvent',
        label: event.title,
        faculty: event.faculty_title,
      });
    },

    setActiveSession(context, { time, currentEvent }) {
      context.state.events
        .filter((event) => event.starting_hour === time && event.id !== currentEvent.id)
        .forEach((event) => {
          context.commit('revokeActiveSession', event);
        });
      context.commit('makeActiveSession', currentEvent);
    },

    sendActiveSession(context, currentEvent) {
      if (currentEvent && context.state.id) {
        const session = context.state.sessions.find((item) => item.session.id === currentEvent.id);
        if (session && session.id) {
          fetch(`${process.env.VUE_APP_API_URL}/respondent/sessions/update/${session.id}`, {
            method: 'PATCH',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Auth-Email': context.state.email,
            },
          });
        }
      }
    },

    saveSession(context, event) {
      fetch(`${process.env.VUE_APP_API_URL}/respondent/sessions/create`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'X-Auth-Email': context.state.email,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_id: event.id,
          session_active: event.active,
        }),
      })
        .then((response) => response.json())
        .then(({ session }) => {
          context.commit('addSession', {
            ...session,
            session: event,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteSession(context, session) {
      const sessionId = context.state.sessions.find((item) => item.session_id === session.id);
      if (sessionId && sessionId.id) {
        fetch(`${process.env.VUE_APP_API_URL}/respondent/sessions/delete/${sessionId.id}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'X-Auth-Email': context.state.email,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then(() => {
            context.commit('removeSession', session);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    /**
     * Persist respondent progress via the API for future restoration.
     */
    async saveSettings(context) {
      // const
      const settings = {
        activeSubcategoryFilter: context.state.activeSubcategoryFilter,
        activeFacultyFilters: context.state.activeFacultyFilters,
        activeDisciplineFilters: context.state.activeDisciplineFilters,
        activeEventStartTimes: context.state.activeEventStartTimes,
        activeCampusTourFilters: context.state.activeCampusTourFilters,
        activeFacultyTourFilters: context.state.activeFacultyTourFilters,
        addedFirstEvent: context.state.addedFirstEvent,
        shownCampusTravelModel: context.state.shownCampusTravelModel,
        shownAllDayModal: context.state.shownAllDayModal,
        appProgress: context.state.appProgress,
        attendingTime: context.state.attendingTime,
      };

      await fetch(`${process.env.VUE_APP_API_URL}/respondent/settings`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Email': context.state.email,
        },
        body: JSON.stringify(settings),
      });
    },

    /**
     * Helper method to set activeEventStartTimes entries based on a given
     * day 'timeSlot'
     */
    setEventStartTimeFilter(context, timeFilter) {
      const { start, end } = timeFilter;
      const newStartTimes = context.rootGetters['events/eventStartHours'].filter((item) => item >= start && item < end);

      context.commit('set', { attendingTime: timeFilter, activeEventStartTimes: newStartTimes });
    },

    /**
     * Update the app progress internal state, and if logged in persist the state
     * via the API.
     */
    setAppProgress(context, appProgress) {
      context.commit('setAppProgress', appProgress);

      if (context.getters.isAuthenticated) {
        context.dispatch('saveSettings');
      }
    },

    togglePlanTab(context){
      if(context.state.planTab === 'timed') {
        context.commit('planTabAllDay');
      } else {
        context.commit('planTabTimed');
      }
    },

    toggleCardOpen(context, cardId){
      const cardPos  = context.state.openCardIds.indexOf(cardId);
      if(cardPos < 0 ){
        context.commit('addOpenCard', cardId);
      } else {
        context.commit('removeOpenCard', cardPos);
      }
    },

    closeCard(context, cardId){
      const cardPos  = context.state.openCardIds.indexOf(cardId);
      if(cardPos >= 0 ){
        context.commit('removeOpenCard', cardPos);
      }
    },

    togglePlanCampus(context, campus){
      if(campus === context.state.planCampus){
        context.commit('set', { planCampus: "All" });
      } else {
        context.commit('set', { planCampus: campus });
      }
    }

  },

  mutations: {
    set(state, respondent) {
      Object.keys(defaultState).forEach((key) => {
        if (typeof respondent[key] !== 'undefined') {
          state[key] = respondent[key];
        }
      });
    },

    reset(state) {
      Object.keys(defaultState).forEach((key) => {
        state[key] = defaultState[key];
      });
    },

    makeActiveSession(state, session) {
      state.events.find((item) => item.id === session.id).active = true;
    },

    revokeActiveSession(state, session) {
      state.events.find((item) => item.id === session.id).active = false;
    },

    addEvent(state, event) {
      state.events.push(event);
    },

    addSession(state, session) {
      const sessionIds = state.sessions.map((item) => item.id);

      if (!sessionIds.includes(session.id)) {
        state.sessions.push(session);
      }
    },

    removeSession(state, session) {
      state.sessions = state.sessions.filter((item) => item.session_id !== session.id);
    },

    removeEvent(state, event) {
      state.events = state.events.filter((item) => item.id !== event.id);

      window.dataLayer.push({
        event: 'RemoveEvent',
        label: event.title,
        faculty: event.faculty_title,
      });
    },

    setAppProgress(state, appProgress) {
      // App progress can only go up as the user unlocks more functionality.
      if (state.appProgress < appProgress) {
        state.appProgress = appProgress;
      }
    },

    toggleSubcategoryFilter(state, subcategory) {
      const subcategoryIndex = state.activeSubcategoryFilter.indexOf(subcategory);

      return subcategoryIndex >= 0
        ? state.activeSubcategoryFilter.splice(subcategoryIndex, 1)
        : state.activeSubcategoryFilter.push(subcategory);
    },

    toggleFacultyFilter(state, faculty) {
      const existingFaculty = state.activeFacultyFilters.indexOf(faculty.id);

      return existingFaculty >= 0
        ? state.activeFacultyFilters.splice(existingFaculty, 1)
        : state.activeFacultyFilters.push(faculty.id);
    },

    toggleCampusTourFilters(state, campus) {
      const existingFilter = state.activeCampusTourFilters.indexOf(campus);

      return existingFilter >= 0
        ? state.activeCampusTourFilters.splice(existingFilter, 1)
        : state.activeCampusTourFilters.push(campus);
    },

    toggleFacultyTourFilters(state, faculty) {
      const existingFilter = state.activeFacultyTourFilters.indexOf(faculty);

      return existingFilter >= 0
        ? state.activeFacultyTourFilters.splice(existingFilter, 1)
        : state.activeFacultyTourFilters.push(faculty);
    },

    toggleDisciplineFilter(state, discipline) {
      const existingDiscipline = state.activeDisciplineFilters.includes(discipline);
      if (existingDiscipline) {
        state.activeDisciplineFilters = state.activeDisciplineFilters.filter((item) => item !== discipline);
      } else {
        state.activeDisciplineFilters.push(discipline);
      }
    },

    planTabAllDay(state) {
      // console.log("TAB ALLDAY?");
      state.planTab = 'allday';
    },
    planTabTimed(state) {
      state.planTab = 'timed';
    },
    addOpenCard(state, id){
      state.openCardIds.push(id);
    },
    removeOpenCard(state, index){
      state.openCardIds.splice(index, 1);
    },
  },

  getters: {
    get(state) {
      return state;
    },

    /**
     * Check if the user has a valid account (completed registration).
     *
     * @returns boolean
     */
    isAuthenticated(state) {
      return Boolean(state.id);
    },

    /**
     * Check if the user has pre-populated their email address (intending to create
     * a new account), but has not yet done so.
     *
     * @returns boolean
     */
    isPreAuthenticated(state) {
      return !state.id && Boolean(state.email);
    },

    /**
     * Check if the user has created a plan yet. They must have at least one event
     * session selected to have a plan.
     */
    hasPlan(state) {
      return state.events.length > 0;
    },

    eventsPerTimeSlot(state) {
      return groupBy(state.events, (v)=>{
        if(v.is_all_day === 1){
          return 'All day';
        }
        return v.starting_hour;
      });
    },

    sortedEvents(state, getters, rootState, rootGetters) {
      let sortedEventSessions = state.events
      if(state.planCampus !== 'All'){
        sortedEventSessions = filter(state.events, (v)=> v.campus === state.planCampus );
      }


      const eventSessionsSortedAndUniqued = sortBy(sortedEventSessions, 'start_time');

      forEach(eventSessionsSortedAndUniqued, (event)=>{
        eventSessionsSortedAndUniqued.find((e) => e.id === event.id).sessions = rootState.events.data.find((e) => e.id === event.event_id) === undefined ? [] : sortBy(rootState.events.data.find((e) => e.id === event.event_id).sessions, 'start_time');
      })

      const eventSessionTimeGroups = groupBy(eventSessionsSortedAndUniqued, (v)=>{
        if(v.is_all_day === 1){
          return 'allDay';
        }
        return 'timed';
      });

      console.log(eventSessionTimeGroups);
      eventSessionTimeGroups.timed = groupBy(eventSessionTimeGroups.timed, (v)=>
        // if(v.is_all_day === 1){
        //   return 'All day';
        // }
        v.starting_hour
      );
      console.log(eventSessionTimeGroups);
      // console.log("eventSessionTimeGroups",eventSessionTimeGroups);

      const eventStartHours = rootGetters['events/eventStartHours'];

      // console.log("eventStartHours",eventStartHours);

      forEach(eventStartHours, (currentStartHour) => {
        // How many events for the time slot?
        // Push empties for the remaining slots
        if (typeof eventSessionTimeGroups.timed[currentStartHour] !== 'undefined') {
          const numberToAdd =
            state.maxEventsPerTimeSlot - parseInt(eventSessionTimeGroups.timed[currentStartHour].length, 10);
          addEmptyEvent(numberToAdd, eventSessionTimeGroups.timed, currentStartHour);
        }
      });
      // console.log("OUTPUT",eventSessionTimeGroups);
      return eventSessionTimeGroups;
    },

    /**
     * Checks the active campus general tours to see if the tours take place in
     * different campuses.
     */
    areActiveCampusToursCrossingCampuses(state, getters, rootState) {
      // Build list of campus titles for all the tours.
      const campusTitles = state.activeCampusTourFilters.map((tourTitle) => {
        const event = rootState.events.data.find((item) => item.title === tourTitle);

        if (!event) {
          throw new Error(`Unable to find event for general campus tour: ${tourTitle}`);
        }

        /* We get the first event session for a tour, assuming all sessions for
        the tour event are in the same campus.
        Spoiler: they're not, but we don't care. */
        const firstSession = event.sessions[0];
        const campusTitle = firstSession.location.campus;
        return campusTitle;
      });

      /* Check if there are any differences in the list campus titles. If so, the
      tours are across multiple campuses. */
      const areCrossingCampuses = !allEqual(campusTitles);

      return areCrossingCampuses;
    },

    currentPlanTab(state){
      return state.planTab === 'timed';
    },
    currentOpenCards(state){
      return state.openCardIds;
    }
  },
};

export default respondentModule;
