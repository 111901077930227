<template>
  <nav ref="jsNavigation" :class="['Navigation', isNavActive ? 'Navigation--is-active' : null]">
    <div class="Navigation__header">
      <button type="button" class="Navigation__back" @click="back()">
        <ChevronIcon class="Chevron--left" />
        Back
      </button>

      <button :class="['hamburger', isNavActive ? 'is-active' : null]" type="button" @click.prevent="toggle">
        <span>
          <span></span>
        </span>
      </button>
    </div>

    <div v-show="isNavActive" class="Navigation__content">
      <h1 class="mb-2">Open Day Planner</h1>
      <h2 class="mb-12">Sunday 28 July 2024</h2>
      <ul class="relative text-white mb-18">
        <li class="mb-10">
          <button class="Button Button--white focus-outline-white" @click="navigate('/my-plan')" :disabled="!respondentHasPlan">
            My plan
          </button>
        </li>

        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="isRespondentAuthenticated ? restart() : start()">
            {{ isRespondentAuthenticated ? 'Change my plan interest areas and preferences' : 'Start creating my plan'}}
            <ChevronIcon />
          </button>
        </li>

        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="navigate('/about-open-day')">
            About Open Day
            <ChevronIcon />
          </button>
        </li>

        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="navigate('/bus-time-table')">
            Shuttle buses
            <ChevronIcon />
          </button>
        </li>

        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="navigate('/campus-maps')">
            Campus maps
            <ChevronIcon />
          </button>
        </li>
        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="navigate('/postgraduate-info')">
            Postgraduate activities at Open Day
            <ChevronIcon />
          </button>
        </li>

        <li class="border-b border-bravo">
          <a :href="this.$config.QUT_STUDY_URL" class="Navigation__link" target="blank"
            >QUT study website
            <ExternalLinkIcon />
          </a>
        </li>

        <li class="border-b border-bravo">
          <a :href="this.$config.MMS_URL + '?open_day'" class="Navigation__link" target="blank"
            >Match My Skills quiz
            <ExternalLinkIcon />
          </a>
        </li>

        <li class="border-b border-bravo">
          <a href="mailto:explore@qut.edu.au?subject=Feedback for planner" class="Navigation__link" target="blank"
            >Feedback
            <ExternalLinkIcon />
          </a>
        </li>

        <li class="border-b border-bravo">
          <button class="Navigation__link" @click="logout" :disabled="!isRespondentAuthenticated">
            Log out
            <Icon :icon="['fas', 'arrow-right-from-bracket']" />
          </button>
        </li>
        <li class="border-b border-bravo">
          <small class="font-delta my-4 px-2" style="display: block;">TEQSA Provider ID: <a href="https://www.teqsa.gov.au/national-register/provider/queensland-university-technology">PRV12079</a> (Australian University)<br>CRICOS No. <a href="https://cricos.education.gov.au/Institution/InstitutionDetails.aspx?ProviderCode=00213J">00213J</a></small>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ChevronIcon from '@/components/svg/ChevronIcon.vue';
import ExternalLinkIcon from '@/components/svg/ExternalLinkIcon.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'Navigation',
  components: {
    Icon,
    ChevronIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      isNavActive: false,
    };
  },
  computed: {
    ...mapState(['respondent']),
    ...mapGetters({
      isRespondentAuthenticated: 'respondent/isAuthenticated',
      respondentHasPlan: 'respondent/hasPlan',
    }),
  },
  methods: {
    navigate(route) {
      this.isNavActive = false;
      this.$router.push(route);
    },

    toggle() {
      this.isNavActive = !this.isNavActive;
    },

    logout() {
      this.$store.dispatch('respondent/logout');
      this.$store.dispatch('localStorage/clearLocalData');
      this.navigate('/');
    },

    back() {
      if(window.history.length > 1)
        this.$router.go(-1)
      else
        this.$router.push('/');
    },

    restart() {
      window.dataLayer.push({
        event: 'Change_my_selection',
      });

      this.navigate('/onboard/1');
    },
    start(){
      this.navigate('/onboard');
    },
  },
};
</script>
