import Vue from 'vue';
import dayjs from 'dayjs';

/**
 * Dates from API are UTC, but represent Brisbane/Australia time.
 */

Vue.filter('time', (datetime) => dayjs(datetime).format('h:mma'));

Vue.filter('timeNoAMPM', (datetime) => dayjs(datetime).format('h:mm'));

Vue.filter('hourFilter', (h) => {
  const startHour = dayjs(h, 'H');
  const endHour = startHour.add(1, 'hour');

  if(h === "8"){
    return `8:45am-9am`;
  }

  if(startHour.format('ha') !== 'Invalid Date' && endHour.format('ha') !== 'Invalid Date')
    return `${startHour.format('ha')}–${endHour.format('ha')}`;


  return h

});
