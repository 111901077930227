// import { forEach, groupBy, sortBy, filter } from 'lodash';


// Some state properties have snake cased names to match the API response contract.
const defaultState = {
  scope: 'QUT.ODP.LocalData',
  data: {},
};

const localStorageModule = {
  namespaced: true,
  state: { ...defaultState },
  actions: {
    checkAuthState(context){
      try {
        const localData = JSON.parse(localStorage.getItem(context.state.scope)) || {};
        console.log(localData);
        context.commit('setData',localData);
      }
      catch (err) {
        console.error('Error parsing localStorage - clearing...', err);
        context.dispatch('resetData');
      }
    },
    setLocalData(context, values) { // i hate this
      const key = values[0];
      const val = values[1];

      const data = JSON.parse(JSON.stringify(context.state.data));

      data[key] = val;

      localStorage.setItem(context.state.scope, JSON.stringify(data));
      context.commit('setData',data);
    },
    clearLocalData(context) {
      localStorage.removeItem(context.state.scope);
      context.commit('resetData');
    }

  },

  mutations: {

    setData(state, data) {
      state.data = data;
    },

    resetData(state) {
      state.data = {};
    },

  },

  getters: {
    getLocalData(state) {
      return state.data;
    },
    hasLocalData(state) {
      return Boolean(state.data.email);
    },

  },
};

export default localStorageModule;


