<template>
  <modal v-show="show" :closeCallback="close" :isVisible="show" :showCloseAction="false" description-id="login-modal">
    <fragment slot="title">
      <h1 ref="mountedFocus" tabindex="0">
        <span v-if="isAutoLogin">Logging you back in</span>
        <span v-else>Existing plan found</span>
      </h1>
    </fragment>

    <fragment slot="body">
      <p v-if="!isAutoLogin" class="mb-4 text-black">
        Do you want to continue as '{{ getLocalData.first_name }}'?
      </p>
      <div v-if="!loading" class="row">
        <button @click="events.data.length === 0 ? waitBeforeLogin() : login()" type="button" class="Button">Yes</button>
        <button @click="close(true)" type="button" class="Button Button--outlined">No, start new plan</button>
      </div>
      <div v-else class="row">
        <LoaderBricks/>
      </div>
    </fragment>
  </modal>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Modal from '@/components/Modal.vue';
import LoaderBricks from '@/components/LoaderBricks.vue';

import { StatusCodes } from 'http-status-codes';
import { convertProgressPointToRoute } from '@/router';
import { formatRespondentFromApi } from '@/lib';


export default {
  name: 'AlreadyLoggedInModal',
  components: {
    Modal,
    LoaderBricks,
  },
  computed: {
    ...mapState(['respondent','events']),
    ...mapGetters({
      alreadyLogged: 'localStorage/hasLocalData',
      getLocalData: 'localStorage/getLocalData',
      getRespondent: 'respondent/get',
      isAuthed: 'respondent/isAuthenticated'
    })
  },
  watch: {
    'events.data': function(v){
      // console.log("EVENTS DATA watcher",v.length, v)
      if(this.loading && v.length > 0){
        this.login();
      }
    }
  },
  mounted(){
    // this.$store.dispatch("localStorage/clearLocalData");
    this.checkAuthState();
    // console.log("localStorage",this.getLocalData);
    // console.log("$route.params",this.$route.query, this.$route.query.email);
    if(!this.isAuthed){
      if(this.$route.query.email !== undefined){
        console.log("email")
        this.email = this.$route.query.email
        this.isAutoLogin = true;
        this.show = true;
        this.waitBeforeLogin();
      } else if(this.alreadyLogged){
        this.email = this.getLocalData.email;
        this.show = true;
        this.$refs.mountedFocus.focus();
      }
    }
  },
  data() {
    return {
      isConfirmingNewUser: false,
      show: false,
      loading: false,
      isAutoLogin: false,
      email: ''
    };
  },
  methods: {
    ...mapActions({
      checkAuthState:'localStorage/checkAuthState',
      clearLocalData:'localStorage/clearLocalData'
    }),
    close(route) {
      this.show = false;
      this.clearLocalData();
      if(route){
        this.$router.replace("/");
      }
    },
    waitBeforeLogin(){
      this.loading = true;
    },
    async login() {
      // console.log("data.length",this.events.data.length)
      this.loading = true;

      const respondent = await this.getExistingRespondent();

      if (respondent) {
        // Logging in an existing user.
        await this.$store.dispatch('respondent/login', respondent);

        const destinationRoute = convertProgressPointToRoute(respondent.appProgress);
        this.$router.replace(destinationRoute);
      }

      this.loading = false;
      this.show = false;
    },

    async getExistingRespondent() {
      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/auth/check?email=${encodeURIComponent(this.email)}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === StatusCodes.OK) {
        const { respondent } = await response.json();
        return formatRespondentFromApi(respondent);
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}
.Loader--bricks {
  margin: auto;
  top: 15px;
}
</style>
