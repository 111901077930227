import Vue from 'vue';
import Vuex from 'vuex';
import { respondent, events, localStorage } from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    respondent,
    events,
    localStorage,
  },
});

export default store;
