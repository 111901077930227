const defaults = {
  fillOpacity: 1,
  strokeWeight: 0,
  scale: 0.035,
  viewBox: '0 0 800 800',
  size: [800,800],
  anchor: [400,400],
};

export default {
  buildings: {
    ...defaults,
    path: 'M781.65 343.62L456.12 420a20 20 0 0 0-15.44 19.47v348.15a8 8 0 0 0 8 8h96.65a8 8 0 0 0 8-8V652.18a8 8 0 0 1 8-8h118a8 8 0 0 1 8 8v135.44a8 8 0 0 0 8 8H792a8 8 0 0 0 8-8V362a18.36 18.36 0 0 0-18.35-18.38zM382.68 231.41L33.84 16.47A22.2 22.2 0 0 0 0 35.37v752.25a8 8 0 0 0 8 8h384a8 8 0 0 0 8-8v-525.2a36.43 36.43 0 0 0-17.32-31.01zm-67.24 468H84.56a8 8 0 0 1-8-8v-43.26a8 8 0 0 1 8-8h230.88a8 8 0 0 1 8 8v43.26a8 8 0 0 1-8 8zm0-136.61H84.56a8 8 0 0 1-8-8v-43.27a8 8 0 0 1 8-8h230.88a8 8 0 0 1 8 8v43.27a8 8 0 0 1-8 8zm0-137.55H84.56a8 8 0 0 1-8-8V374a8 8 0 0 1 8-8h230.88a8 8 0 0 1 8 8v43.26a8 8 0 0 1-8 7.99zM210 285.45H84.56a8 8 0 0 1-8-8v-43.26a8 8 0 0 1 8-8H210a8 8 0 0 1 8 8v43.26a8 8 0 0 1-8 8z',
  },
  toilets: {
    ...defaults,
    path: 'M227 197a44 44 0 0 0-43-37H68a44 44 0 0 0-43 37L1 344a44 44 0 0 0 36 50 48 48 0 0 0 8 0h8l29 295a44 44 0 0 0 44 40h1a44 44 0 0 0 44-40l28-294h9a44 44 0 0 0 43-45 49 49 0 0 0 0-6zM60 67a66 66 0 1 0 66-66 66 66 0 0 0-66 66m696 328a44 44 0 0 0 43-45 49 49 0 0 0 0-6l-23-147a44 44 0 0 0-44-37H617a44 44 0 0 0-44 37l-24 147a44 44 0 0 0 36 50 48 48 0 0 0 8 0h9l-24 170a22 22 0 0 0 18 24h24l10 101a44 44 0 0 0 44 40h1a44 44 0 0 0 44-40l9-100h21a22 22 0 0 0 22-22v-3l-24-169zM608 67a67 67 0 1 0 66-67 67 67 0 0 0-66 67M399 1a16 16 0 0 1 16 16v696a16 16 0 0 1-16 16 16 16 0 0 1-16-16V17a16 16 0 0 1 16-16z',
  },
  tours: {
    ...defaults,
    path: 'M504 228a45 45 0 0 0-45-38H341a45 45 0 0 0-45 38l-24 150a45 45 0 0 0 45 53h8l30 302a45 45 0 0 0 45 41h1a45 45 0 0 0 45-41l29-302h9a45 45 0 0 0 45-53zM332 94a68 68 0 1 0 136 0 68 68 0 1 0-136 0m334 398c-72-13-166-21-266-21s-195 8-266 21C71 504 0 524 0 574c0 42 51 59 67 65 27 9 64 18 108 24l-3 23a7 7 0 0 0 10 8l103-44a7 7 0 0 0 1-12l-88-67a7 7 0 0 0-12 5l-3 22c-53-8-85-17-103-24 15-6 42-14 88-21 64-11 147-16 232-16s167 5 232 16c46 7 72 15 87 21-25 10-80 24-179 33a33 33 0 0 0 3 65h3c67-6 125-15 168-27 23-7 86-24 86-71 0-50-72-70-134-82z',
  },
  'information-tents': {
    ...defaults,
    path: 'M400 0a400 400 0 0 0 0 800 400 400 0 0 0 0-800zm38 563a38 38 0 1 1-76 0V353a38 38 0 0 1 76 0zm-38-288a38 38 0 1 1 38-38 38 38 0 0 1-38 38z',
  },
  'food-outlets': {
    ...defaults,
    path: 'M777 58h-30a7 7 0 0 1-7-7V7a7 7 0 0 0-7-7H348a7 7 0 0 0-7 7v44a7 7 0 0 1-7 7h-31a7 7 0 0 0-7 7v35a7 7 0 0 0 7 7h474a7 7 0 0 0 7-7V65a7 7 0 0 0-7-7zm-436 75l1 15a7 7 0 0 0 7 7h65a164 164 0 0 1 164 164v293a113 113 0 0 1-29 76 7 7 0 0 0 2 10 7 7 0 0 0 3 1h145a7 7 0 0 0 6-6l34-560a7 7 0 0 0-6-8H348a7 7 0 0 0-7 7 4 4 0 0 0 0 1zM6 605h540a6 6 0 0 1 6 6v1a88 88 0 0 1-87 87H88a88 88 0 0 1-88-87 6 6 0 0 1 5-7zm132-424h276a138 138 0 0 1 138 138 6 6 0 0 1-6 6H6a6 6 0 0 1-6-6 138 138 0 0 1 138-138zM48 343h456a48 48 0 0 1 48 48v1a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48v-1a48 48 0 0 1 48-48zm464 163h-27c-57 3-145 11-196 34a31 31 0 0 1-26 0c-51-23-138-31-195-34H40a40 40 0 0 0-40 40v1a40 40 0 0 0 40 40h472a41 41 0 0 0 1-81zm28-49H12a12 12 0 0 0-12 12v6a12 12 0 0 0 11 13h3c17 0 173 1 249 34a31 31 0 0 0 26 0c76-33 232-34 249-34h2a12 12 0 0 0 12-12v-7a12 12 0 0 0-12-12z',
  },
  'fairy-flosspopcorn': {
    ...defaults,
    path: 'M777 58h-30a7 7 0 0 1-7-7V7a7 7 0 0 0-7-7H348a7 7 0 0 0-7 7v44a7 7 0 0 1-7 7h-31a7 7 0 0 0-7 7v35a7 7 0 0 0 7 7h474a7 7 0 0 0 7-7V65a7 7 0 0 0-7-7zm-436 75l1 15a7 7 0 0 0 7 7h65a164 164 0 0 1 164 164v293a113 113 0 0 1-29 76 7 7 0 0 0 2 10 7 7 0 0 0 3 1h145a7 7 0 0 0 6-6l34-560a7 7 0 0 0-6-8H348a7 7 0 0 0-7 7 4 4 0 0 0 0 1zM6 605h540a6 6 0 0 1 6 6v1a88 88 0 0 1-87 87H88a88 88 0 0 1-88-87 6 6 0 0 1 5-7zm132-424h276a138 138 0 0 1 138 138 6 6 0 0 1-6 6H6a6 6 0 0 1-6-6 138 138 0 0 1 138-138zM48 343h456a48 48 0 0 1 48 48v1a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48v-1a48 48 0 0 1 48-48zm464 163h-27c-57 3-145 11-196 34a31 31 0 0 1-26 0c-51-23-138-31-195-34H40a40 40 0 0 0-40 40v1a40 40 0 0 0 40 40h472a41 41 0 0 0 1-81zm28-49H12a12 12 0 0 0-12 12v6a12 12 0 0 0 11 13h3c17 0 173 1 249 34a31 31 0 0 0 26 0c76-33 232-34 249-34h2a12 12 0 0 0 12-12v-7a12 12 0 0 0-12-12z',
  },
  'parking': {
    ...defaults,
    path: 'M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM192 256h48c17.7 0 32-14.3 32-32s-14.3-32-32-32H192v64zm48 64H192v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V288 168c0-22.1 17.9-40 40-40h72c53 0 96 43 96 96s-43 96-96 96z'
  },
  'shuttle-buses': {
    ...defaults,
    scale: 0.05,
    viewBox: '0 0 500 500',
    path: 'M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8a24 24 0 0 0-24 24v80a24 24 0 0 0 24 24h8v160a32 32 0 0 0 32 32v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32h192v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8a24 24 0 0 0 24-24v-80a24 24 0 0 0-24-24zM112 400a32 32 0 1 1 0-64 32 32 0 0 1 0 64zm16-112a32 32 0 0 1-32-32V128a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32v128a32 32 0 0 1-32 32H128zm272 112a32 32 0 1 1 0-64 32 32 0 0 1 0 64z',
    size: [500,500],
    anchor: [250,250],
  },
  'support-services': {
    ...defaults,
    path: 'M683.62 119.28l-1.69-1.68A392 392 0 0 0 400.18 0h-.8C180.19 0 1.59 178.11 1.1 397.47v1.68a402.81 402.81 0 0 0 115.75 283.27A397.75 397.75 0 0 0 399.14 800h4.14a390.65 390.65 0 0 0 278.65-117.6c75.39-75 117.09-174.79 117.44-281.12s-40.77-206.52-115.75-282zM577.9 515.17a209.33 209.33 0 0 0 0-232.06l70.82-70.81a308.25 308.25 0 0 1 0 373.68zm-177.63 196.4h-1.15a305.34 305.34 0 0 1-185.77-63l70-70a207.9 207.9 0 0 0 116.9 34.79 204 204 0 0 0 116.1-34.73l69.93 70.56a306.94 306.94 0 0 1-185.38 62.3zm-.09-624.06h1.08a305.32 305.32 0 0 1 185.86 63L516 221.71a218.32 218.32 0 0 0-232.31 0l-71.13-71.08a307.72 307.72 0 0 1 186.72-63.12zm89.07 400.43a128.73 128.73 0 0 1-178.91 0A126.07 126.07 0 0 1 399.79 273h.28a125.23 125.23 0 0 1 89.06 37.11c48.8 49.05 48.87 128.77.12 177.83zM87.81 399.17a311.09 311.09 0 0 1 62.3-186.73l70.74 70.74a210.13 210.13 0 0 0 .08 232.1l-70.21 71.44a309.38 309.38 0 0 1-62.91-187.55z',
  },
  unknown: {
    ...defaults,
    scale: 0.05,
    viewBox: '0 0 500 500',
    path: 'M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z',
    size: [500,500],
    anchor: [250,250],
  },
  'welcome-tents': {
    ...defaults,
    viewBox: '0 0 576 512',
    path: 'M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z',
  },
  'in-plan': {
    ...defaults,
    viewBox: '0 0 576 512',
    path: 'M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z',
  },
  activity: {
    ...defaults,
    viewBox: '0 0 24 24',
    path: 'M12 2C8.1 2 5 5.1 5 9C5 14.2 12 22 12 22S19 14.2 19 9C19 5.1 15.9 2 12 2M14.5 13L12 11.5L9.5 13L10.2 10.2L8 8.3L10.9 8.1L12 5.4L13.1 8L16 8.3L13.8 10.2L14.5 13Z',
    scale: 1.5,
    size: [24,24],
    anchor: [12,22],
  },
};
