/**
 * Convert an API respondent into its frontend representation.
 *
 * @param {object} respondent Respondent object from API.
 */
const formatRespondentFromApi = ({ settings: settingsJson, ...respondent }) => {
  let settings = {};

  if (settingsJson) {
    try {
      settings = JSON.parse(settingsJson);
    } catch (err) {
      console.error('Unable to parse settings JSON.', settingsJson);
    }
  }

  const formattedRespondent = {
    ...respondent,
    ...settings,
    events: respondent.sessions
      ? respondent.sessions.map((item) => ({ ...item.session, active: Boolean(item.active) }))
      : [],
  };

  return formattedRespondent;
};

export default formatRespondentFromApi;
