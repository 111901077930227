<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArrowRightFromBracket,
  faBars,
  faBus,
  faCheckCircle,
  faCircleInfo,
  faClock,
  faComment,
  faCompass,
  faExclamationCircle,
  faMapLocation,
  faMapMarkerAlt,
  faSkullCrossbones,
  faSpinner,
  faTent,
  faTrash,
  faTrashAlt,
  faXmark,
  faGripVertical,
  faAnglesRight,
  faPenToSquare,
  faEnvelope,
  faRotateRight
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowRightFromBracket,
  faBars,
  faBus,
  faCheckCircle,
  faCircleInfo,
  faClock,
  faComment,
  faCompass,
  faExclamationCircle,
  faMapLocation,
  faMapMarkerAlt,
  faSkullCrossbones,
  faSpinner,
  faTent,
  faTrash,
  faTrashAlt,
  faXmark,
  faGripVertical,
  faAnglesRight,
  faPenToSquare,
  faEnvelope,
  faRotateRight,
);

export default {
  ...FontAwesomeIcon,
  name: 'Icon',
};
</script>
