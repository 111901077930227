import 'current-script-polyfill';
import 'core-js';

import 'es6-promise/auto';
import 'fetch-polyfill';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './main.scss';
import Vue from 'vue';
import Fragment from 'vue-fragment';
import Vue2TouchEvents from 'vue2-touch-events'
import config from './config';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';

dayjs.extend(customParseFormat);

function doVueThings(){
  // console.log("AFTER",document.cookie);
  Vue.config.productionTip = false;
  window.events = new Vue();
  window.flash = (
    message,
    level = 'success',
    // eslint-disable-next-line camelcase -- Argument name cannot be changed.
    auto_hide = true,
    options = {},
    timeout = 3500
  ) => {
    window.events.$emit('flash', {
      message,
      level,
      // eslint-disable-next-line camelcase -- Setting name cannot be changed.
      auto_hide,
      options,
      timeout
    });
  };

  Vue.use(config);
  Vue.use(Fragment.Plugin);
  Vue.use(Vue2TouchEvents);

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

// console.log("BEFORE",document.cookie);
// if(!document.cookie.split(";").some((item) => item.trim().startsWith("openday.qut.edu.au="))){
//   const params = new URLSearchParams(document.location.search);
//   if(params.get("early-access-allowed")){
//     const date = new Date()
//     const days = new Date(date.setDate(date.getDate() + 10)).toUTCString();
//     console.log("10 days date",days)
//     document.cookie = `openday.qut.edu.au=true; max-age=604800; expires=${days}; SameSite=Lax`;
//     doVueThings();
//   } else {
//     console.log("No cookie no param = BE GONE!!");
//     window.location.replace("https://www.qut.edu.au/study/events/qut-open-day-2023");
//   }
// } else {
  doVueThings()
// }
